export function getString(stringid, component = 'moodle', a) {
    var _a, _b, _c;
    return (_c = (_b = (_a = window.M.str) === null || _a === void 0 ? void 0 : _a[component]) === null || _b === void 0 ? void 0 : _b[stringid]) !== null && _c !== void 0 ? _c : `[[${stringid}/${component}]]`;
}
export function getCoreModule(module) {
    return new Promise((resolve, reject) => {
        window.require([module], module => {
            resolve(module);
        });
    });
}
