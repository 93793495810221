<script lang="ts">
    import { createEventDispatcher, onMount, tick } from 'svelte/internal'
    import { getCoreModule } from '@/misc/utilities'
    import type { Events, GenericObject, ModalDataMoodleFormContent, TFragment } from '@/components/Modals/types'
    import Loader from '@/components/Loader.svelte';

    const dispatch = createEventDispatcher()

    export const events: Events = null
    export let data: ModalDataMoodleFormContent

    let isLoading = true
    let isBusy = false
    $: updateBusyState(isBusy)

    let content: string = ''
    let contentElement: HTMLDivElement

    const {
        fragment,
        methodname,
        afterSubmit
    } = data

    async function load() {
        const { html, js } = await loadFragment(fragment)
        isLoading = false
        content = html
        await tick()
        appendScriptTag(js)
    }

    async function loadFragment(fragment: TFragment) {
        const Fragment = await getCoreModule('core/fragment') as {
            loadFragment: (component: string, callback: string, contextid: number, params: GenericObject) => any
        }

        const { component, callback, contextid, params } = fragment

        return await new Promise<{ html: string, js: string}>((resolve) => {
            Fragment.loadFragment(component, callback, contextid, params).done((html: string, js: string) => {
                resolve({ html, js })
            })
        })
    }

    function appendScriptTag(content: string) {
        const newScript = document.createElement('script')
        newScript.type = 'text/javascript'
        newScript.innerHTML = content

        document.head.append(newScript)
    }

    async function onSubmit(e: SubmitEvent) {
        e.preventDefault()

        if (isBusy) {
            return false
        }

        isBusy = true

        const formElement = e.target as HTMLFormElement

        const formData = new FormData(formElement)
        const formDataObj = {}

        formData.forEach((value, key) => {
            formDataObj[key] = value.toString()
        })

        const formParamString = JSON.stringify((new URLSearchParams(formDataObj)).toString())

        const url  = new URL('/lib/ajax/service.php', window.location.origin)
        url.searchParams.append('sesskey', window.M.cfg.sesskey)

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify([{
                methodname,
                index: 0,
                args: {
                    jsonformdata: formParamString
                },
            }])
        })

        if (response.ok) {
            const json = (await response.json())[0]

            if (json.error) {
                isLoading = true

                const params = { ...fragment.params }
                params.jsonformdata = formParamString

                const { html, js } = await loadFragment({
                    ...fragment, params
                })

                content = html
                isLoading = false

                await tick()

                isBusy = false

                return console.error(e)
            }

            if (afterSubmit) {
                afterSubmit(response, e, closeModal)
            }
            else {
                closeModal()
            }
        }
    }

    function updateBusyState(isBusy: boolean) {
        if (!contentElement) {
            return
        }

        const formElement = contentElement.querySelector('form')
        const buttons = formElement.querySelectorAll('input[type="submit"]') as NodeListOf<HTMLInputElement>

        for (const button of buttons) {
            if (isBusy) {
                button.classList.add('disabled')
            }
            else {
                button.classList.remove('disabled')
            }
        }
    }

    function addSubmit(node: HTMLElement) {
        const formElement = node.querySelector('form')

        formElement.removeEventListener('submit', onSubmit)
        formElement.addEventListener('submit', onSubmit)
    }

    function closeModal() {
        dispatch('closemodal')
    }

    onMount(() => {
        (window as any).skipClientValidation = false

        load()
    })
</script>

<div bind:this={contentElement} class="modal-body">
    {#if isLoading}
        <div class="d-flex flex-row justify-content-center">
            <Loader />
        </div>
    {:else if content}
        <div use:addSubmit>
            {@html content}
        </div>
    {/if}
</div>
